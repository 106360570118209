<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Properties</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Properties"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: parentRouteName }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Properties
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="grid-row" v-if="isGridView">
            <v-data-table
              :headers="tableHeaders"
              :items="properties"
              no-data-text="No Archived properties"
            >
              <template v-slot:item.address="{ item }">
                {{ item.address.city }} {{ item.address.short }}
              </template>
              <template v-slot:item.price="{ item }">
                {{ item.current_price.formatted_price }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      class="mr-2"
                      color="yellow lighten-4 yellow--text text--darken-3"
                      v-on="on"
                      @click="openRestore(item)"
                    >
                      <v-icon small>mdi-restore</v-icon>
                    </v-btn>
                  </template>
                  <span>Restore</span>
                </v-tooltip>
                <v-btn
                  x-small
                  depressed
                  color="green lighten-4 green--text"
                  v-on="on"
                  class="mr-2"
                  :to="{
                    name: 'module-melrose-properties-individual',
                    params: { propertyId: item.id },
                  }"
                >
                  <v-icon x-small>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Property</v-card-title>
        <v-card-text
          >Are you sure you want to restore this property?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: String,
    parentRouteName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      gridData: this.properties,
      buttonSwitchViewText: "Switch to List",
      isGridView: true,
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        property: {},
      },
      tableHeaders: [
        { text: "Address", value: "address" },
        { text: "Status", value: "status" },
        { text: "Customer", value: "customer.full_name" },
        { text: "Type", value: "type" },
        { text: "Price", value: "price" },
        { text: "Viewing Done By?", value: "viewings_performed_by" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    properties() {
      let properties = this.$store.getters["melrose/propertiesStore/archived"];

      if (this.searchTerm !== "") {
        properties = properties.filter((p) => {
          const short = p.address.short.toLowerCase();
          const county = p.address.county.toLowerCase();
          const customerName = p.customer.full_name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            short.includes(searchTerm) ||
            county.includes(searchTerm) ||
            customerName.includes(searchTerm)
          );
        });
      }

      return properties;
    },
  },

  methods: {
    openRestore(property) {
      this.restoreDialog.property = property;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.property = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("melrose/propertiesStore/restoreProperty", {
          appId,
          propertyId: this.restoreDialog.property.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
